<template>
<div class="preview_area show_sidebar" v-if="modelValue">
    <div class="global_setting">
        <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
        <div class="tabs_content">
            <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="new-progress-tracking-form" class="progress-template">
                <div class="content_area" v-if="!categorySelected">
                    <div class="section_header">
                        <h2>Select Category</h2>
                    </div>
                    <ul class="category_item">
                        <li v-for="(category, c) in categories" :key="c">
                            <div class="cat_item" :class="{'active' : form.category_id == category.id}">
                                <img :src="require(`@/assets/images/thumb/${category.thumb}`)" :alt="category.title">
                                <div class="action_btn">
                                    <button type="button" class="use" @click="useCategory(category.id)">Use {{ category.title }}</button>
                                </div>
                            </div>
                            <h4>{{ category.title }}</h4>
                        </li>
                    </ul>
                </div>
                <div class="content_area sm" v-if="categorySelected">
                    <div class="section_header">
                        <h2>Scenario Details</h2>
                    </div>
                    <div class="setting_wpr wrapper_box">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Scenario Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: New Progress Tracking" rules="required" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Description</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                    <Field name="description" v-model="form.description" label="description" autocomplete="off">
                                        <textarea name="description" autocomplete="off" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="description" class="text-danger" />
                            </div>
                        </div>
                        <label for="has_thumbnail" class="switch_option capsule_btn border-0">
                            <h5 class="large">Add A Thumbnail</h5>
                            <input type="checkbox" id="has_thumbnail" v-model="form.is_thumbnail" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="upload_image m-0" v-if="form.is_thumbnail">
                            <image-library v-model="form.dashboard_thumb" image-type="dashboard-thumb" :is-inside="true" />
                        </div>
                        <label for="saved_styling" class="switch_option capsule_btn border-0">
                            <h5 class="large">Use Saved Styling?</h5>
                            <input type="checkbox" id="saved_styling" v-model="form.use_default" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn mr-auto ml-0" @click="categorySelected = false;">Back</button>
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</button>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { mapState, mapActions } from 'vuex'

const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

export default {
    name: 'New Page',

    data() {
        return {
            form: {
                name: '',
                is_thumbnail: 0,
                use_default: 0,
                dashboard_thumb: '',
                category_id: '',
                description: '',
            },
            loader: false,
            categorySelected: false,
        }
    },

    props: {
        modelValue: Boolean,
        refreshAsset: {
            type: Function,
            default: () => { }
        }
    },

    emit: ['update:modelValue'],

    components: {
        Field,
        Form,
        ErrorMessage,
        ImageLibrary,
    },

    watch: {
        modelValue(value) {
            const vm = this;

            if (value) {
                vm.resetForm();
            }
        }
    },

    computed: mapState({
        categories: state => state.progressTrackingModule.defaultCategories,
        categoryLoader: state => state.progressTrackingModule.defaultCategoriesLoader,
    }),

    methods: {
        ...mapActions({
            createProgressTracking: 'progressTrackingModule/createProgressTracking',
        }),

        closeModal () {
            const vm = this;
            vm.categorySelected = false,

            vm.$emit('update:modelValue', false);
        },

        handleSubmitForm () {
            const vm = this;

            const form = vm.$refs['new-progress-tracking-form'];

            form.validate().then((result) => {
                if (result.valid) {
                    vm.handleCreateProgressTracking(form);
                }
            });
        },

        handleCreateProgressTracking (form) {
            const vm = this;

            vm.form.setFieldError = form.setFieldError;
            vm.loader = true;

            vm.createProgressTracking(vm.form).then((result) => {
                vm.loader = false;

                if (result) {
                    vm.refreshAsset();
                    vm.closeModal();
                    vm.categorySelected = false;
                }

            });
        },

        resetForm () {
            const vm = this;

            vm.form = {
                name: '',
                use_default: 0,
                dashboard_thumb: '',
                description: '',
            };
        },

        useCategory (id) {
            const vm = this;

            vm.form.category_id = id;
            vm.categorySelected = true;
        }
    }
}
</script>

<style scoped>
.step_content {
    padding: 20px 30px 10px 30px;
    flex: 1;
}

.step_content .action_wpr {
    width: 100%;
    padding-top: 5px;
}

.switch_option h5 {
    text-align: left;
    margin: 0;
}

.upload_image.image_create {
    margin-bottom: 0;
}

.upload_image.image_create .upload_wpr {
    height: 100%;
}

:deep(.upload_image .upload_wpr) {
    height: 100%;
}

.global_setting .tabs_content .content_area {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
}

.global_setting .tabs_content .content_area.sm {
    max-width: 800px;
    width: 100%;
}

.wrapper_box {
    padding: 15px 30px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
}

.progress-template {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.category_item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.category_item li {
    padding: 15px;
    line-height: 0;
    width: 33.333%;
}

.category_item li .cat_item {
    position: relative;
    background: #d9d9d9;
    border-radius: 10px;
    overflow: hidden;
}

.category_item li .cat_item img {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.category_item li h4 {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    margin: 12px 0 0 0;
}

.category_item li .cat_item.active {
    background: #fff;
    border-color: #cee2ff;
}

.category_item li .cat_item.active h4 {
    color: #2f7eed;
}

.category_item li .action_btn {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
}

.category_item li .action_btn button {
    min-width: 140px;
    padding: 12px 20px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
    font-family: inter, sans-serif;
    color: #121525;
    background: #fff;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 20px;
}

.category_item li .action_btn button.use {
    background: #f2a31d;
    color: #fff;
}

.category_item li:hover .action_btn {
    opacity: 1;
}
</style>
